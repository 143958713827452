// ============================================================================
// SuperAdminApi
// -------------
// SuperAdmin module related API
// ============================================================================

// -------
// Imports
// -------
import API from '@/apis/SuperAdminApi'

import { Bus as AppEventBus, Events as AppEvents } from '@/events/AppEvents'

import TeamApi from '@/apis/TeamManagerApi'
import ThemesService from '@/services/Themes/ThemesService'

import DefaultInterceptor from '@/plugins/axios/DefaultInterceptor'
import DomainAlreadyExists from '@/exceptions/SuperAdmin/DomainAlreadyExistsException'

const returnEmptyOn404 = function (err) {
	if (err.response.status === 404) {
		return null
	} else {
		return Promise.reject(err)
	}
}

const getFormDataFromObject = function (obj) {
	const formData = new FormData()
	Object.keys(obj).forEach(key => {
		if (obj[key]) {
			return formData.append(key, Array.isArray(obj[key]) ? JSON.stringify(obj[key]) : obj[key])
		}
	})
	return formData
}

// -------
// Exports
// -------
export default {
	listHoldings: function () {
		return API.listHoldings()
			.then(response => response.data.data)
	},
	listAccountingFirms: function (query = {}) {
		return API.listAccountingFirms(query).then(response => {
			return response.data
		})
	},
	getApplication: function (applicationId) {
		return API.getApplication(applicationId).then(response => {
			return response.data.data
		})
	},
	listApplications: function (query = {}) {
		return API.listApplications(query).then(response => {
			return response.data
		})
	},
	createAccountingFirm: function (data) {
		return API.createAccountingFirm(data, { show_error: false })
			.then(res => res.data.data)
			.catch(error => {
				if (error?.response?.data?.error) {
					const {
						error: { code },
						status
					} = error.response.data

					if (status === 422 && code === 'domain_already_exists') {
						throw new DomainAlreadyExists(data.domain)
					} else {
						error.response.config.show_error = true
						return DefaultInterceptor.onResponseError(error)
					}
				} else {
					error.response.config.show_error = true
					return DefaultInterceptor.onResponseError(error)
				}
			})
	},
	createCatalogTheme: function (data) {
		return API.createCatalogTheme(data).then(res => res.data.data)
	},
	createCatalogTreeFolder: function (catalogTreeStructureId, catalogTreeFolderId, data) {
		return API.createCatalogTreeFolder(catalogTreeStructureId, catalogTreeFolderId, data)
	},
	createCatalogTreeStructure: function (data) {
		return API.createCatalogTreeStructure(data).then(res => res.data.data)
	},
	createFlag: function (data) {
		return API.createFlag(data).then(res => res.data.data)
	},
	createWhiteLabel: function (accountingFirmId, data) {
		return new Promise((resolve, reject) => {
			const promises = []

			data.forEach(whiteLabel => {
				const formData = new FormData()
				formData.append('logo', whiteLabel.logo)
				formData.append('primary_color', whiteLabel.primary_color)
				formData.append('secondary_color', whiteLabel.secondary_color)
				promises.push(API.createWhiteLabel(accountingFirmId, whiteLabel.theme, formData))
			})

			Promise.allSettled(promises).then(results => {
				results.forEach(aResult => {
					if (aResult.status === 'rejected') {
						reject(aResult.reason)
					} else {
						resolve(results)
					}
				})
			})
		})
	},
	listWhiteLabels: function (accountingFirmId) {
		return new Promise(resolve => {
			const promise = API.listWhiteLabels(accountingFirmId)

			resolve(promise)
		}).then(response => response.data.data)
	},
	updateWhiteLabel: function (accountingFirmId, theme, data) {
		let apiData = new FormData()
		if (data.hasOwnProperty('logo')) {
			apiData.append('logo', data.logo)
		}
		if (data.hasOwnProperty('primary_color')) {
			apiData.append('primary_color', data.primary_color)
		}
		if (data.hasOwnProperty('secondary_color')) {
			apiData.append('secondary_color', data.secondary_color)
		}

		return API.updateWhiteLabel(accountingFirmId, theme, apiData).then(response => response.data.data)
	},
	dearchiveAccountingFirm: function (accountingFirmId) {
		return API.restoreAccountingFirm(accountingFirmId)
	},
	deleteAccountingFirm: function (accountingFirmId, params = {}) {
		return API.deleteAccountingFirm(accountingFirmId, params)
	},
	deleteCatalogTreeFolder: function (catalogTreeStructureId, catalogTreeFolderId) {
		return API.deleteCatalogTreeFolder(catalogTreeStructureId, catalogTreeFolderId)
	},
	deleteCatalogTheme: function (id) {
		return API.deleteCatalogTheme(id).then(res => res.data)
	},
	deleteCatalogTreeStructure: function (id) {
		return API.deleteCatalogTreeStructure(id, { show_error: false }).catch(err => {
			const errorCode = err?.response?.data?.error?.code

			if (errorCode) {
				AppEventBus.emit(AppEvents.SNACKBAR_ERROR, window.vueInstance.$t(`super_admin.errors.${errorCode}`))
			} else {
				err.response.config.show_error = true
				return DefaultInterceptor.onResponseError(err)
			}

			return Promise.reject(err)
		})
	},
	deleteFlag: function (id) {
		return API.deleteFlag(id).then(res => res.data)
	},
	getAccountingFirm: function (accountingFirmId) {
		return API.getAccountingFirm(accountingFirmId).then(res => res.data.data)
	},
	getAccountingFirmModules: function () {
		return API.getAccountingFirmModules().then(res => res.data.data)
	},
	getAccountingFirms: function (archived = false) {
		let params = {}
		if (archived) {
			params.archived = true
		}
		return API.getAccountingFirms({
			params: params
		}).then(res => res.data.data)
	},
	getCatalogTreeFolder: function (catalogTreeStructureId, catalogTreeFolderId) {
		return API.getCatalogTreeFolder(catalogTreeStructureId, catalogTreeFolderId).then(res => res.data.data)
	},
	getCatalogTreeStructures: function () {
		return API.getCatalogTreeStructures().then(res => res.data.data)
	},
	getDurations: function () {
		return API.getDurations().then(res => res.data.data)
	},
	getFlags: function () {
		return API.getFlags().then(res => res.data.data)
	},
	listCatalogThemes: function (params) {
		return API.listCatalogThemes(params)
			.then(response => {
				return response.data.data
			})
	},
	getACLList: ThemesService.getACLList,
	getCatalogTreeTools: function () {
		const url = 'sa/catalog-tree-tools'
		return API.getCatalogTreeTools(url).then(res => res.data.data)
	},
	getUserByEmail: function (email) {
		return TeamApi.getUserByEmail(email).then(res => res.data.data)
			.then(users => users?.[0] ?? null)
	},
	getModulesSubscriptionsContent: function () {
		return API.getModulesSubscriptions().then(res => res.data.data)
	},
	getModules: function () {
		const url = '/sa/modules'
		return API.getModules(url).then(res => res.data.data)
	},
	getTreeview: function (catalogTreeStructureId) {
		return API.getFullTreeStructure(catalogTreeStructureId).then(res => res.data.data)
	},
	setSubscription: function (accountingFirmId, moduleId, isActive, customParameters) {
		if (isActive) {
			return API.subscribeToModule(accountingFirmId, moduleId, customParameters)
		}
		return API.unsubscribeToModule(accountingFirmId, moduleId)
	},
	updateAccountingFirm: function (accountingFirmId, data) {
		return API.updateAccountingFirm(accountingFirmId, data, { show_error: false })
			.then(res => res.data.data)
			.catch(error => {
				if (error?.response?.data?.error) {
					if (error.response.data.status === 422 && error.response.data.error.code === 'domain_already_exists') {
						throw new DomainAlreadyExists(data.domain)
					} else {
						error.response.config.show_error = true
						return DefaultInterceptor.onResponseError(error)
					}
				} else {
					error.response.config.show_error = true
					return DefaultInterceptor.onResponseError(error)
				}
			})
	},
	updateCatalogTheme: function (id, data) {
		return API.updateCatalogTheme(id, data).then(res => res.data)
	},
	updateCatalogTreeFolder: function (catalogTreeStructureId, catalogTreeFolderId, data) {
		return API.updateCatalogTreeFolder(catalogTreeStructureId, catalogTreeFolderId, data)
	},
	updateCatalogTreeStructure: function (id, data) {
		return API.updateCatalogTreeStructure(id, data).then(res => res.data.data)
	},
	modifyTreeStructures: function (catalogTreeStructureId) {
		return API.modifyTreeStructures(catalogTreeStructureId)
	},
	updateFlag: function (id, data) {
		return API.updateFlag(id, data).then(res => res.data)
	},
	updateModule: function (moduleId, data) {
		return API.updateModule(moduleId, data).then(res => res.data.data)
	},
	getApplications: function () {
		return API.getApplications().then(res => res.data.data)
	},
	createApplication: function (data) {
		return API.createApplication(data).then(res => res.data.data)
	},
	deleteApplication: function (applicationId) {
		return API.deleteApplication(applicationId).then(res => res.data.data)
	},
	updateApplication: function (id, data) {
		return API.updateApplication(id, data).then(res => res.data.data)
	},
	createAccountingFirmApplication: function (accountingFirmId, applicationId) {
		return API.createAccountingFirmApplication(accountingFirmId, applicationId)
	},
	deleteAccountingFirmApplication: function (accountingFirmId, applicationId) {
		return API.deleteAccountingFirmApplication(accountingFirmId, applicationId)
	},
	createCustomers: function (accountingFirmId, file) {
		return API.createCustomers(accountingFirmId, file, { show_error: false })
	},
	createUsers: function (accountingFirmId, file) {
		return API.createUsers(accountingFirmId, file, { show_error: false })
	},
	getMobileApp: function (mobileAppId) {
		return API.getMobileApp(mobileAppId).then(res => res?.data?.data)
	},
	listMobileApp: function (reset = false) {
		return API.listMobileApp(reset).then(res => res?.data?.data)
	},
	createMobileAppSync: function () {
		return API.createMobileAppSync().then(res => res?.data?.data)
	},
	getMobileAppSync: function () {
		return API.getMobileAppSync({ show_error: false })
			.then(res => res?.data?.data)
			.catch(returnEmptyOn404)
	},
	createMobileAppUpgradeBatch: function () {
		return API.createMobileAppUpgradeBatch().then(res => res?.data?.data)
	},
	getMobileAppUpgradeBatch: function () {
		return API.getMobileAppUpgradeBatch({ show_error: false })
			.then(res => res?.data?.data)
			.catch(returnEmptyOn404)
	},
	createMobileApp: function (mobileApp) {
		const mobileAppFormData = getFormDataFromObject(mobileApp)
		return API.createMobileApp(mobileAppFormData).then(res => res?.data?.data)
	},
	createMobileAppWizard: function (mobileApp) {
		const mobileAppFormData = getFormDataFromObject(mobileApp)
		return API.createMobileAppWizard(mobileAppFormData).then(res => res?.data?.data)
	},
	updateMobileAppWizard: function (mobileAppId, data) {
		return API.updateMobileAppWizard(mobileAppId, data).then(res => res?.data?.data)
	},
	updateMobileApp: function (mobileApp) {
		const mobileAppFormData = new FormData()
		if (!mobileApp.logo) {
			mobileApp.logo_file_path = null
		}
		Object.keys(mobileApp).forEach(key => {
			if (mobileApp[key]) {
				mobileAppFormData.append(key, Array.isArray(mobileApp[key]) ? JSON.stringify(mobileApp[key]) : mobileApp[key])
			}
		})
		return API.updateMobileApp(mobileApp.id, mobileAppFormData).then(res => res?.data?.data)
	},
	upgradeMobileApp: function (mobileAppId) {
		const mobileAppFormData = new FormData()
		mobileAppFormData.append('upgrade', true)
		return API.updateMobileApp(mobileAppId, mobileAppFormData).then(res => res?.data?.data)
	},
	upgradeAndUpdateMobileApp: function (mobileAppId) {
		const mobileAppFormData = new FormData()
		mobileAppFormData.append('upgrade_and_update', true)
		return API.updateMobileApp(mobileAppId, mobileAppFormData).then(res => res?.data?.data)
	},
	deleteMobileApp: function (mobileAppId) {
		return API.deleteMobileApp(mobileAppId)
	},
	deleteMobileAppWizard: function (mobileAppId) {
		return API.deleteMobileAppWizard(mobileAppId)
	},
	getAccountingFirmList: function () {
		return API.getAccountingFirmList().then(res => res?.data?.data)
	},
	getNPSSurveySettings: function () {
		return API.getNPSSurveySettings().then(res => res?.data?.data)
	},
	updateNPSSurveySettings: function (data) {
		return API.updateNPSSurveySettings(data)
	},
	getAutomationTypes: function () {
		return API.getAutomationTypes().then(res =>
			res.data.data.map(r => {
				r.label = window.vueInstance.$t('client_workspace.application.automation_type.types.' + r.name)
				return r
			})
		)
	},
	executeCommand: function (data) {
		return API.executeCommand(data)
	},
	checkDomain: function (domain) {
		return API.checkDomain(domain)
	}
}
